import { get, post, put } from "../../services/api";

const save = async (data) =>
    post({ type: "user", service: `customers/${data.uuid || ''}`, data: data });

const saveInternal = async (data) =>
    post({ type: "user", service: `customers/internal`, data });

const saveLead = async (data) =>
    post({ type: "user", service: `marketing/leads/registration`, data });

const removeClient = async (id, data) =>
    put({ type: "user", service: `customers/remove/${id}`, data });

const externalSave = async (data) =>
    post({ type: "user", service: `customers/${data.uuid}`, data: data });

const edit = async (id, data) =>
    put({ type: "user", service: `customers/${id}`, data });

const registerEdition = async (id, data) =>
    put({ type: "user", service: `customers/${id}/registration`, data });

const findAll = async () =>
    get({ type: "user", service: "customers/" });

const findById = async (id) =>
    get({ type: "user", service: `customers/${id}` });

const findByEmail = async (email) =>
    get({ type: "user", service: `customers/email/${email}` });

const findByUUID = async (uuid) =>
    get({ type: "user", service: `customers/uuid/${uuid}` });

const getUUID = async () =>
    get({ type: "user", service: "customers/external-uuid" });

const getRepurchaseRate = async (period, yearMonth) =>
    post({
        type: "user",
        service: "customers/statistics/repurchase",
        data: { period, yearMonth },
    });

const getUsageRate = async (data) =>
    post({ type: "user", service: "customers/statistics/usage", data });

const getTextFormatRequirement = (id) =>
    get({ type: "user", service: `customers/${id}/text-format-requirement` });

export default {
    save,
    saveInternal,
    saveLead,
    externalSave,
    edit,
    registerEdition,
    removeClient,
    findAll,
    findById,
    findByUUID,
    getUUID,
    getRepurchaseRate,
    getUsageRate,
    getTextFormatRequirement,
    findByEmail,
};
