import {
    List,
    Tooltip, Avatar, Button, IconButton, Grid
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { AttachFileOutlined, Visibility } from "@material-ui/icons";

import documentsService from "../../../domain/services/solicitation/documents.service";
import modalities from "../../../domain/constant/modalities.constant";
import { useState } from "react";
import FirstRequestDownloadModals from "../request-details/firstRequestDownloadModals";
import AnswerAdditionalQuestionsModal from "../request-details/asnwerAdditionalQuestionsModal";
import CustomerSingleUpsellModal from "../request-details/CustomerSingleUpsellModal";
import loginService from "../../../domain/services/login.service";
import customerOfficeService from "../../../domain/services/client/customer.office.service";

const RequestServiceDocumentList = ({ documents = [], openPreview, serviceName = '', isNotion, request, onDownloadDocument }) => {

    const [openFirstDownloadModals, setOpenFirstDownloadModals] = useState(false);
    const [openQuestionsConfirmModal, setOpenQuestionsConfirmModal] = useState(false);
    const [isSingleUpsellModalOpen, setIsSingleUpsellModalOpen] = useState(false);

    const getItemDocument = (item) => (
        <div>
            <p
                style={{
                    color: '#000000DE',
                    fontFamily: 'satoshi, sans-serif',
                    fontSize: 13,
                    fontWeight: 500
                }}
            >
                {item.isProcessMainDocument ? "Processo" : !!isNotion ? modalities.notion.label : serviceName}
                <p
                    style={{
                        color: '#00000099',
                        fontFamily: 'satoshi, sans-serif',
                        fontSize: 12,
                        fontWeight: 400,
                        marginTop: 2
                    }}
                >
                    {item.name}
                </p>
            </p>

            {item.forCorrection && (
                <span style={{
                    background: 'orange', color: '#FFF', padding: 3,
                    marginLeft: 10, fontSize: 10, borderRadius: 5, fontWeight: 600
                }}>
                    Correção
                </span>
            )}
        </div>
    );

    const getDocumentIcon = (doc) => {

        const Icon = doc.icon || AttachFileOutlined;

        return (
            <Icon style={{ fontSize: 16 }} />
        )
    }

    const openQuestionsModals = () => {
        const user = loginService.getUserAuthentication();
        const customer = { ...user.customer, name: user.name };

        const incompleteRegister = user.customer.isRegisterIncomplete;
        const officeQuestions = customerOfficeService.getQuestionsToShow(customer.profileQuestions);

        if (!incompleteRegister && !officeQuestions.length) return;

        setOpenQuestionsConfirmModal(true);
    }

    const handleDownload = async (doc) => {
        await documentsService.downloadFile(doc.url, doc.name);

        if (!request.isFirstCustomerRequest) return;

        openQuestionsModals();
    }

    const getDocumentsActions = (doc) => {

        if (doc.action)
            return doc.action();

        return (
            <>
                <Grid item>
                    <Tooltip title="Visualizar documento">
                        <IconButton color="primary" onClick={() => openPreview(doc)}>
                            <Visibility style={{ fontSize: 16 }} />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip title="Baixar arquivo">
                        <Button
                            size="small" color="primary" variant="outlined"
                            onClick={() => handleDownload(doc)}
                        >
                            Baixar
                        </Button>
                    </Tooltip>
                </Grid>
            </>
        )
    }

    const getContent = () => {

        if (!documents.length)
            return (
                <Grid style={{ width: '100%', marginTop: 5 }}>
                    <Alert severity='error'>
                        <AlertTitle>
                            <strong>Não foi possível encontrar os documentos</strong>
                        </AlertTitle>
                        Por favor tente novamente mais tarde ou entre
                        em contato com nosso suporte.
                    </Alert>
                </Grid>
            );

        return (
            <>
                {openFirstDownloadModals && (
                    <FirstRequestDownloadModals onClose={() => {
                        setOpenFirstDownloadModals(false);
                        setIsSingleUpsellModalOpen(true);
                    }} />
                )}

                <CustomerSingleUpsellModal open={isSingleUpsellModalOpen} />

                <AnswerAdditionalQuestionsModal
                    open={openQuestionsConfirmModal}
                    onCancel={() => {
                        setOpenQuestionsConfirmModal(false);
                        setOpenFirstDownloadModals(true);
                    }}
                    onOk={() => {
                        setOpenQuestionsConfirmModal(false);
                        setOpenFirstDownloadModals(true)
                    }}
                />

                <List
                    component="nav" dense={true}
                    style={{ width: "100%", height: "auto", display: "flex", flexDirection: 'column', gap: 10 }}
                >
                    {documents.map((doc, i) => (
                        <div style={{ width: "100%", display: "flex", justifyContent: 'space-between', alignItems: 'center', gap: 50 }} key={i}>

                            <div style={{ display: "flex", gap: 20 }}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        alt={doc.name}
                                        style={{
                                            background: 'blue', color: "white", width: 24,
                                            height: 24, fontSize: 10, fontWeight: 600
                                        }}
                                    >
                                        {getDocumentIcon(doc)}
                                    </Avatar>
                                </div>

                                {getItemDocument(doc)}

                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {getDocumentsActions(doc)}
                            </div>

                        </div>
                    ))}
                </List>
            </>
        )
    }

    return getContent();
}

export default RequestServiceDocumentList;