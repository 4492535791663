import { Button, Tooltip, Typography } from "@material-ui/core";
import * as MaterialIcons from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import requestService from "../../../domain/services/request/request.service";
import RequestPremiumConfirmModal from "../request.premium.confirm.modal";
import stepsSolicitation from "../../../domain/constant/steps.solicitation.constant";
import PremiumRequestPopup from "../popups/premiumRequestPopup";
import LoadingSpin from "../../LoadingSpin";
import { packages } from "../../../domain/constant/marketing/product.constant";
import solicitationService from "../../../domain/services/solicitation/solicitation.service";
import loginService from "../../../domain/services/login.service";

export default function ToggleButtonField({
    name,
    label,
    setValue,
    request,
    fieldCustomProps = {},
    setAlert,
    isEdit,
}) {

    const premiumButtonRef = useRef(null);

    const [showPremiumServiceModal, setShowPremiumServiceModal] = useState(false);
    const [loading, setLoading] = useState(false);

    let {
        color = '#376FD0',
        activeLabel = label,
        icon,
        activeIcon,
    } = fieldCustomProps;

    const isInfinityRequest = request.billedMarketingPackageId === packages.infinity.id;

    const customActiveConditions = {
        linkPremiumFaciliter: req => solicitationService.isPremiumService(req),
    };

    const additionalRelatedValues = {
        linkPremiumFaciliter: 'hasActivePremiumService',
    };

    const UnactiveIcon = icon ? MaterialIcons[icon] : null;
    const ActiveIcon = activeIcon ? MaterialIcons[activeIcon] : null;

    const customActiveCondition = customActiveConditions[name];
    const active = customActiveCondition ? customActiveCondition(request) : request[name];

    const changeValue = (value) => {
        if (value === null) return

        const additionalValue = additionalRelatedValues[name];

        setValue(name, value);

        if (!additionalValue) return;

        setValue(additionalValue, value);
    }

    const removePremiumServiceFromRequest = async (status) => {
        setLoading(true);
        await requestService.removePremiumServiceFromRequest(request.id)
            .then((res) => {
                changeValue(status);

                if(res.data.success)
                    setAlert('Serviço premium cancelado com sucesso!', 'success');
                else
                    setAlert(res.data.message, 'error');
                
            })
            .finally(() => setLoading(false));
    }

    const handlePremiumServiceEdit = (status) => {
        if (!status) return removePremiumServiceFromRequest(status);
        setShowPremiumServiceModal(true);
    }

    const handleActiveChange = status => {
        if (status) return setShowPremiumServiceModal(true);
        if (!isEdit) return changeValue(status);
        handlePremiumServiceEdit(status);
    }

    useEffect(() => {
        return () => {
            changeValue(null);
        }
    }, []);

    const renderIcon = () => active ? <ActiveIcon style={{ color: color }} /> : <UnactiveIcon style={{ color: color }} />;

    const allowedStatus = [stepsSolicitation.NEW, stepsSolicitation.ANALYSE, stepsSolicitation.LINK, stepsSolicitation.FROZEN];
    const pendingCondition = request.status === stepsSolicitation.PENDING && request.isFinancialPending;

    const isDisabled = ((!allowedStatus.includes(request.status) && !pendingCondition) || isInfinityRequest) && !loginService.isInternal();

    const buttonComponent = () => (
        <div style={{ display: 'flex', justifyContent: 'end', gap: 12 }} >
            {name === 'linkPremiumFaciliter' && (
                <PremiumRequestPopup anchorRef={premiumButtonRef} isNewRequest={request.status === 'NEW'} />
            )}
            <Button
                style={{ borderColor: active && color, backgroundColor: active && `${color}10`, pointerEvents: active && 'none' }}
                variant='outlined'
                startIcon={!!icon && renderIcon()}
                onClick={() => handleActiveChange(true)}
                size='large'
                disabled={isDisabled}
                ref={premiumButtonRef}
            >
                {active ? activeLabel : label}
            </Button>
            {active && (
                <Button
                    variant='outlined'
                    size="large"
                    onClick={() => handleActiveChange(false)}
                    disabled={isDisabled}
                >
                    {loading ? <LoadingSpin /> : 'Cancelar'}

                </Button>
            )}
        </div>
    )

    const getTooltipTitle = () => {
        if (isInfinityRequest)
            return 'Benefício de Faciliter Premium Ativado!'

        return 'Só é possível solicitar e cancelar serviços premium nos status de "Caixa de entrada" e "A vincular"';
    }

    return (
        <>
            <RequestPremiumConfirmModal
                open={showPremiumServiceModal}
                request={request}
                setValue={(name, value) => changeValue(value)}
                name={name}
                isEdit={isEdit}
                setOpen={setShowPremiumServiceModal}
                setAlert={setAlert}
                onClose={() => setShowPremiumServiceModal(false)}
            />

            {isDisabled ? (
                <Tooltip title={<Typography variant="body1">{getTooltipTitle()}</Typography>}>
                    {buttonComponent()}
                </Tooltip>
            ) : (
                buttonComponent()
            )}
        </>
    );
}