import { useState, useEffect } from 'react';

export default function QuantityField({
    name, label, required, extraInfo, setFieldValue, onError,
    value, confirmationLabel, needConfirmation, fieldProps
}) {

    const [creditAmount, setCreditAmount] = useState(value ? parseInt(value) : 1);

    useEffect(() => {
        setFieldValue(name, creditAmount);
    }, [creditAmount]);

    const handleInputChange = (e) => {
        const value = parseInt(e.target.value) || 0;
        setCreditAmount(value);
    };

    return (
        <div className="flex flex-col gap-2" style={{ paddingTop: 9.5, paddingBottom: 9.5, paddingLeft: 15, paddingRight: 15 }}>
            <div className="font-semibold">{label}</div>
            {extraInfo && (
                <div className="text-xs text-gray-500">{extraInfo}</div>
            )}
            <div className="flex w-fit">
                <button
                    onClick={() => setCreditAmount(prev => Math.max(prev - 1, 1))}
                    className="px-4 py-2 rounded-l-lg border border-[#dedede] font-semibold hover:text-[#376fd0] hover:border-[#376fd0] hover:bg-[#00000004] transition-[border-color_.3s_ease]"
                >
                    -
                </button>
                <input
                    type="number"
                    value={creditAmount}
                    onChange={handleInputChange}
                    className="w-16 text-center px-4 py-2 border-t border-b border-[#dedede] outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
                <button
                    onClick={() => setCreditAmount(prev => Math.min(prev + 1, 50))}
                    className="px-4 py-2 rounded-r-lg border border-[#dedede] font-semibold hover:text-[#376fd0] hover:border-[#376fd0] hover:bg-[#00000004] transition-[border-color_.3s_ease]"
                >
                    +
                </button>
            </div>
        </div>
    );
}
