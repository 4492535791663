import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import utilsProvider from "../providers/utils.provider";
import { copyWriter } from './fields/step.legend.config';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DeleteOutlined } from "@material-ui/icons";

const RequestServicePrices = ({ isSmall, services, removeService, request }) => {


    return (
        <>

            <React.Fragment key="solicitationCheckout">
                <Grid item>
                    <Divider />
                </Grid>

                <Grid
                    item spacing={isSmall ? 6 : 0}
                    direction={isSmall ? 'column' : 'row'}
                    justifyContent='space-between' container
                >

                    <Grid item xs={isSmall ? 12 : 4}>

                        <Typography variant='h6' gutterBottom>
                            <strong>{copyWriter.checkout.default.title}</strong>
                        </Typography>

                        <Typography variant='caption' color='textSecondary'>
                            {copyWriter.checkout.default.legend}
                        </Typography>

                    </Grid>

                    <Grid item xs={isSmall ? 12 : 7} container>

                        <Grid xs={12}>

                            <div
                                className='p-8 md:p-10 rounded-lg bg-[#fff] border border-solid border-[#ededed] shadow-[0px_4px_4px_#0001]'
                            >

                                {!services.length ? (

                                    <div role="status" class="p-4 space-y-4 divide-y divide-gray-200 rounded animate-pulse light:divide-gray-700 md:p-6 light:border-gray-700">
                                        <div class="flex items-center justify-between">
                                            <div>
                                                <div class="h-2.5 bg-gray-300 rounded-full light:bg-gray-600 w-24 mb-2.5"></div>
                                                <div class="w-32 h-2 bg-gray-200 rounded-full light:bg-gray-700"></div>
                                            </div>
                                            <div class="h-2.5 bg-gray-300 rounded-full light:bg-gray-700 w-12"></div>
                                        </div>
                                        <div class="flex items-center justify-between pt-4">
                                            <div>
                                                <div class="h-2.5 bg-gray-300 rounded-full light:bg-gray-600 w-24 mb-2.5"></div>
                                                <div class="w-32 h-2 bg-gray-200 rounded-full light:bg-gray-700"></div>
                                            </div>
                                            <div class="h-2.5 bg-gray-300 rounded-full light:bg-gray-700 w-12"></div>
                                        </div>
                                        <span class="sr-only">Carregando...</span>
                                    </div>

                                ) : (
                                    <>
                                        <Grid container direction="column" spacing={6}>

                                            <Grid item container direction="column" key="0">
                                                <div className="flex flex-col gap-2 px-2 py-1">
                                                    {services.map(service => (

                                                        <div className="flex justify-between items-center">
                                                            <div>
                                                                <p className="text-sm leading-none">
                                                                    {service.serviceName}
                                                                </p>
                                                            </div>

                                                            <div className="flex justify-end items-center flex-grow gap-6">
                                                                <div className="text-right">
                                                                    {utilsProvider.formatNumberCurrency(service.servicePrice)}
                                                                </div>

                                                                <div>
                                                                    <button
                                                                        className={`text-[#376fd0] text-sm flex items-center gap-1 underline ${service.serviceId != request.mainServiceId && !service.hearingAdvance ? 'block' : 'invisible'}`}
                                                                        onClick={() => removeService(service)}
                                                                    >
                                                                        remover
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>

                                            {(
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>

                                                    <Grid item container spacing={1} alignItems="center" xs={12}>

                                                        <Grid item xs style={{ marginRight: '3%' }}>
                                                            <Typography align="right" color="textSecondary" style={{ lineHeight: 1.1 }}>
                                                                Valor total:
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item>
                                                            <Typography align="right">
                                                                <strong>
                                                                    {utilsProvider.formatNumberCurrency(
                                                                        services.reduce((acc, service) =>
                                                                            acc += (parseFloat(service.servicePrice) || 0), 0
                                                                        )
                                                                    )}
                                                                </strong>
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>
                                                </>
                                            )}

                                        </Grid>
                                    </>
                                )}

                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </React.Fragment >

        </>
    )
}

export default RequestServicePrices;