import queryString from 'query-string';

const parseIntFromQueryString = (rawString, item) => {

    const queryValue = getFromQueryString(rawString, item);
    if (!queryValue) {
        return null;
    }

    const valueToProcess = Array.isArray(queryValue) ? queryValue[0] : queryValue;
    if (typeof valueToProcess !== 'string') {
        return null;
    }

    const numericFromQueryString = valueToProcess.replace(/[^0-9]/g, '');

    return isNaN(parseInt(numericFromQueryString)) ? null : parseInt(numericFromQueryString);
}

const getFromQueryString = (rawString, item) =>
    queryString.parse(rawString)[item];

const checkRedirectionInUrl = (search, key, callback) => {

    const param = getFromQueryString(search, key);

    if (!param)
        return;

    callback(param);
}

const getAnchor = () => {

    const currentUrl = document.URL;
    const splitedUrl = currentUrl.split('#');

    return (splitedUrl.length > 1) ? splitedUrl[1] : null;
}

const getAllQueryParams = (search) => queryString.parse(search);

export default {
    parseIntFromQueryString,
    getFromQueryString,
    checkRedirectionInUrl,
    getAnchor,
    getAllQueryParams
};