import { Card, CardContent, Typography, Grid, Button, Dialog, DialogContent } from "@material-ui/core";
import { DescriptionOutlined, FileCopyOutlined, Description } from "@material-ui/icons";

const RequestDocumentTypeConfirmationModal = ({ getInput, handleClose, loginService, shouldDisplayProcessMainDocumentType, documents, showDocumentsOptionsToCustomer }) => {

    const expandView = () => {
        const itemsToShow = options.filter(item => item.allowView());
        return itemsToShow.length > 2;
    }

    const options = [
        {
            icon: DescriptionOutlined,
            label: 'Documentos de serviço',
            description: 'Documentos finalizados dos serviços',
            key: 'serviceDocument',
            allowView: () => loginService.isInternal() || loginService.isFaciliter(),
            isMultiple: true
        },
        {
            icon: FileCopyOutlined,
            label: `Documentos ${loginService.isInternal() ? 'do cliente' : 'da solicitação'}`,
            description: 'Arquivos úteis para a produção dos documentos',
            isMultiple: true,
            allowView: () => loginService.isInternal() || showDocumentsOptionsToCustomer()

        },
        {
            icon: Description,
            label: 'Documento do processo',
            description: 'Documento com todos os dados do processo',
            key: 'processMainDocument',
            allowView: () => shouldDisplayProcessMainDocumentType(documents)
        },
    ]

    const gridProps = expandView(documents) ? { sm: 4 } : { xs: 6 };

    const itemStyle = () => {
        if (!expandView(documents)) return {
            height: 200
        }
        return {
            width: 180, height: 210
        }
    }

    const getItem = (data, index) => {
        return (
            <Grid item {...gridProps}>
                <Card variant="outlined" style={itemStyle()}>
                    <div>

                        {getInput({
                            id: `files${index}`,
                            multiple: data.isMultiple,
                            key: data.key
                        })}

                        <label htmlFor={`files${index}`}>
                            <Button component="span">
                                <CardContent>
                                    <Grid
                                        container alignItems="center"
                                        direction="column"
                                    >

                                        <data.icon style={{
                                            color: 'blue', fontSize: 60,
                                            marginBottom: 10
                                        }} />

                                        <Typography
                                            variant="h5" align="center"
                                            style={{ marginBottom: 5 }}
                                        >
                                            {data.label}
                                        </Typography>

                                        <Typography align="center" style={{
                                            color: 'grey', lineHeight: 1
                                        }} >
                                            {data.description}
                                        </Typography>

                                    </Grid>
                                </CardContent>
                            </Button>
                        </label>
                    </div>
                </Card>
            </Grid>
        )
    }


    return (
        <Dialog
            open={true} maxWidth={expandView(documents) ? "sm" : "xs"} fullWidth
            onClose={handleClose}
        >
            <DialogContent style={{ marginBottom: 10 }}>
                <Grid container direction='column' spacing={2}>

                    <Grid item style={{ marginBottom: 10 }}>
                        <Typography variant='h5'>
                            Selecione o tipo de documento
                        </Typography>
                    </Grid>

                    <Grid item container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        {options.map((item, index) => {
                            if (item.allowView()) return getItem(item, index);
                        })}
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default RequestDocumentTypeConfirmationModal;