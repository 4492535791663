import { MenuBookOutlined, MouseOutlined, PersonOutline, ScheduleOutlined } from "@material-ui/icons";
import Card from "../../../shared/Card";
import Col from "../../../shared/grid/Col";
import Row from "../../../shared/grid/Row";
import Statistic from "../../../shared/Statistic";
import useRequest from "../../../../hooks/useRequest";
import BlogsService from "../../../../domain/services/marketing/blog.service";
import { useEffect, useState } from "react";
import { useBlog } from "../../../../context/marketing/blogContext";
import { Tooltip } from "@material-ui/core";

export default function BlogMetrics() {

    const { blogData } = useBlog();

    const [findMetrics, findingMetrics] = useRequest(BlogsService.findMetrics);

    const [metrics, setMetrics] = useState({});

    const formatTime = () => {
        const hours = metrics.averageTimeSpent?.hours || 0;
        const minutes = metrics.averageTimeSpent?.minutes || 0;
        const seconds = metrics.averageTimeSpent?.seconds || 0;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const getButtonClicks = () => {
        return (metrics.buttonClicks || []).reduce((sum, button) => sum + button.amount, 0);
    }

    const calculateClickPercentage = () => {
        if (!parseInt(metrics.totalPageViews)) return 0;
        return (100 / metrics.totalPageViews * getButtonClicks()).toFixed(2);
    }

    useEffect(() => {
        findMetrics({ id: blogData.id })
            .then(setMetrics)
            .catch(() => { })
    }, []);

    return (
        <Row gutter={[24, 24]}>
            <Col span={24} md={12} lg={8} xl={6}>
                <Card>
                    <Statistic
                        title="Leituras"
                        value={metrics.totalPageViews || 0}
                        loading={findingMetrics}
                        prefix={<MenuBookOutlined />}
                    />
                </Card>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
                <Card>
                    <Statistic
                        title="Tempo Médio de Leitura"
                        value={formatTime()}
                        loading={findingMetrics}
                        prefix={<ScheduleOutlined />}
                    />
                </Card>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
                <Card>
                    <Statistic
                        title="Leitores Únicos"
                        value={metrics.uniqueVisitors || 0}
                        loading={findingMetrics}
                        prefix={<PersonOutline />}
                    />
                </Card>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
                <Card>
                    <Statistic
                        title="Cliques"
                        value={getButtonClicks()}
                        loading={findingMetrics}
                        prefix={<MouseOutlined />}
                        suffix={(
                            <Tooltip title={<span className="text-xs">Cliques em relação à leituras</span>} placement="top">

                                <div className="text-green-600 font-semibold">
                                    {calculateClickPercentage()}%
                                </div>
                            </Tooltip>
                        )}
                    />
                </Card>
            </Col>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={24} lg={12} xl={12}>
                        <Card
                            title="Cliques por botão"
                        >
                            {!(metrics.buttonClicks || []).length && (
                                <div className="text-center text-[#0007]">Nenhum clique registrado</div>
                            )}

                            <div className="flex flex-col gap-2">
                                {(metrics.buttonClicks || [])
                                    .sort((a, b) => b - a)
                                    .map(button => (
                                        <div className="flex justify-between items-center">
                                            <div>{button.name || 'Outros Botões'}</div>
                                            <div className="flex items-center gap-4">
                                                {button.amount}
                                                <div className="w-40 h-5 rounded bg-[#0002] overflow-hidden">
                                                    <div
                                                        className="h-full bg-primaryLight"
                                                        style={{ width: `${100 / getButtonClicks() * button.amount}%` }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}