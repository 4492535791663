import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import SimpleModal from "../../shared/SimpleModal";

import announcementService from "../../../domain/services/marketing/announcement.service";
import loginService from "../../../domain/services/login.service";

import { announcements } from "../../../domain/constant/announcements.constant";
import { packages } from "../../../domain/constant/marketing/product.constant";
import { automationSellerUserIds } from "../../../domain/constant/marketing/purchase.constant";

export default function CustomerSingleUpsellModal({ open }) {

    const [isOpen, setIsOpen] = useState(open);

    const history = useHistory();

    useEffect(() => {

        if (!open)
            return;

        checkToShowModal();

    }, [open]);

    const checkToShowModal = async () => {

        const alreadyShowed = announcementService.isShowed(announcements.singlePackageUpsell);
        const lastPurchaseWasSingle = loginService.getUserAuthentication()?.customer?.lastPurchaseWasSingle;
        if (alreadyShowed || !lastPurchaseWasSingle)
            return;

        setIsOpen(true);
    }

    const handleSubmit = () =>
        announcementService
            .markViewed(announcements.customerRepurchaseVoucher, { clickedCta: true })
            .then(() => history.push(`/checkout/package/${packages.start.id}?sellerUserId=${automationSellerUserIds.singlePackageUpsell}`))

    const handleClose = () => {
        setIsOpen(false);
        announcementService.markViewed(announcements.singlePackageUpsell, { clickedCta: false }).catch(() => { })
    }


    return (
        <SimpleModal
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            bodyStyle={{ padding: 0 }}
        >
            <div className="flex flex-col md:flex-row relative">
                <div className="absolute right-4 top-4">
                    <IconButton
                        aria-label="close"
                        size='small'
                        onClick={handleClose}
                    >
                        <Close className="!text-[#aaa]" />
                    </IconButton>
                </div>

                <div className="text-base text-center py-6 px-8 md:py-16 md:px-14">

                    <div className="text-xl font-bold">
                        Parabéns! Você acaba de receber a sua primeira petição elaborada pelo nosso super assistente jurídico!
                    </div>

                    <div className="mt-6">
                        Agora que sentiu o gostinho da nossa tecnologia, que tal adquirir um super pacote com <strong>petições a 160 reais</strong> e turbinar ainda mais o seu escritório?
                    </div>

                    <div className="mt-6">
                        Pode ficar tranquilo, este valor <strong>não é mensalidade!</strong> Ele se torna crédito no seu cadastro para você usar de acordo com seu ritmo, sem preocupações e sem fidelização. 😀
                    </div>

                    <div className="mt-7 w-full flex justify-center">
                        <button
                            className="w-full py-4 text-lg font-semibold rounded bg-[#376fd0] text-white"
                            style={{ letterSpacing: 1 }}
                            onClick={handleSubmit}

                        >
                            ACEITAR OFERTA
                        </button>
                    </div>
                </div>
            </div>
        </SimpleModal>
    );
}