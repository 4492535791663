import documentsService from '../../solicitation/documents.service';
import requestServicesService from '../../solicitation/request.services.service';

const find = async (requestId) =>
    documentsService
        .findServiceDocuments(requestId)
        .then(res => res.data || {});

const getRequestServices = (requestId) =>
    requestServicesService
        .findProductionServices(requestId)
        .then((res) => mapServices(res.data))
        .catch(() => []);

const mapServices = (services = []) => {

    const mappedServices = services.map(item => ({
        ...item,
        value: item.serviceId,
        name: item.serviceName || item.name
    }));

    const emptyAnswer = { value: 0, name: 'Nenhum (não recomendado)' }

    return [emptyAnswer, ...mappedServices];
}

const getStandardName = async (request, document, service) => {

    const getName = documentNameByService[service.key]

    if (!getName)
        return null;

    return getName(request, document);
}

const documentNameByService = {
    petition: (request, document) => getPetitionStandardName(request, document),
    issuePaymentFee: (request, document) => getIssuePaymentFeeStandardName(request, document)
}

const getIssuePaymentFeeStandardName = (request, document) => {
    return `GUIA DE PAGAMENTO - ID ${request.mainId || request.id}.${document.extension}`
}

const getPetitionStandardName = async (request, document) => {

    const formatName = document.isVisibleCustomer && document.isServiceMainDocument

    if (!formatName)
        return null;

    const numberOfCorrections = request.additionalInformation?.numberOfCorrections;

    return `PETIÇÃO FINALIZADA - ID ${request.mainId || request.id} - ${request.modality}${numberOfCorrections ? ` (Cor ${numberOfCorrections})` : ''}.${document.extension}`
}

export default {
    getStandardName,
    find,
    getRequestServices,
};
