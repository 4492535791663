import { Grid } from '@material-ui/core';

import AnalysisFactsFundamentalsForm from './analysis/analysis.facts.fundamentals.component';
import SkeletonLoading from '../SkeletonLoading';

import stepFormFields from './fields/request.fields.config';

import requestFieldsService from '../../domain/services/request/request.fields.service'

import utilsProvider from '../../components/providers/utils.provider';

const FactsFundamentalsForm = ({ request = {}, stepFields, setValue, isEdit, fieldProps, fieldsWithError, setAlert }) => {

    const dependencyFieldValidation = (field) => {

        const dependencyFieldValueA = request[field.dependencyField];
        const dependencyFieldValueB = ((request?.fields || {})[field.dependencyField]?.values || [])[0]?.value;
        const dependencyFieldValueC = request.fields[field.dependencyField]?.value;

        const dependencyValue = field.dependencyValue || 'true';
        const result = (!field.dependencyField || utilsProvider.checkStringContains(dependencyFieldValueA, dependencyValue) || utilsProvider.checkStringContains(dependencyFieldValueB, dependencyValue) || utilsProvider.checkStringContains(dependencyFieldValueC, dependencyValue));

        return result
    }

    if (request.referenceAnalysisId)
        return <AnalysisFactsFundamentalsForm values={request} isEdit={isEdit} fieldProps={fieldProps} setAlert={setAlert} />

    return (
        <SkeletonLoading
            isDataLoading={!request.id || !stepFields.length}
            variant='rect' size={{ height: 110 }}
        >
            <Grid
                container spacing={8} direction='column'
                style={requestFieldsService.checkDisableFieldsStyle(request, isEdit)}
            >

                {stepFields
                    .filter(field => dependencyFieldValidation(field))
                    .map(item => ({ ...stepFormFields.getComponentField({ field: item, request, setValue, isEdit, fieldProps, fieldsWithError }) }))
                }

            </Grid>
        </SkeletonLoading>
    );
}

export default FactsFundamentalsForm;