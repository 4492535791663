import { AccountBalance, Description, FindInPage } from "@material-ui/icons";

export const requestServices = {
    PETITION: 'petition',
    ANALYSIS: 'analysis',
    CALCULATION: 'calculation',
    HEARING: 'hearing',
    HEARINGANALYSIS: 'hearingAnalysis',
    HEARINGINPERSON: 'hearingInPerson',
    NOTION: 'notion',
    CONTRACT: 'contract',
    ISSUEPAYMENTFEE: 'issuePaymentFee',
    structure: {
        petition: {
            id: 1,
            name: "Petição",
            key: "petition",
            databaseKey: 'forwardToPetition',
            icon: Description,
            grammaticalGender: 'feminine',
            description: 'Um profissional jurídico especializado irá elaborar um documento formal, conforme as necessidades específicas do seu caso. Você receberá uma petição detalhada e juridicamente correta, pronta para ser protocolada no processo judicial.',
        },
        analysis: {
            id: 2,
            name: "Indicação de Petição",
            key: "analysis",
            databaseKey: 'forwardToAnalysis',
            icon: FindInPage,
            grammaticalGender: 'feminine',
            description: 'Uma análise detalhada do seu caso por um advogado, que indicará qual petição é mais adequada para a sua situação.',
            noDocuments: true,
        },
        calculation: {
            id: 3,
            name: "Cálculo",
            key: "calculation",
            databaseKey: 'forwardCalculationServiceId',
            icon: AccountBalance,
            grammaticalGender: 'masculine',
            description: 'Um profissional especializado realizará os cálculos necessários, como liquidação de sentença, atualização de valores, juros e correções monetárias. Você receberá um relatório detalhado e preciso com todos os cálculos necessários para o seu caso, pronto para ser utilizado no processo judicial.',
        },
        hearing: {
            id: 4,
            name: "Audiência Virtual",
            key: "hearing",
            icon: Description,
            grammaticalGender: 'feminine',
            description: 'Você contará com a representação de um advogado experiente em uma sessão por videoconferência. Você receberá um relatório completo sobre os resultados da audiência e as próximas etapas a serem seguidas, além de ter a garantia de uma defesa profissional e eficaz no ambiente digital.',
        },
        contract: {
            id: 6,
            name: "Contrato",
            key: "contract",
            icon: Description,
            grammaticalGender: 'masculine',
            description: 'Um advogado especializado redigirá um documento personalizado conforme as suas necessidades e especificações. Você receberá um contrato juridicamente válido e detalhado, pronto para ser assinado pelas partes envolvidas, garantindo que todos os direitos e deveres estejam claramente definidos e protegidos.',
        },
        notion: {
            id: 7,
            name: "Parecer",
            key: "notion",
            icon: Description,
            grammaticalGender: 'masculine',
            description: 'Uma análise jurídica detalhada sobre a sua questão específica, elaborada por um profissional qualificado. O parecer fornecerá uma avaliação profunda e fundamentada, com orientações claras e sugestões sobre a melhor forma de agir, ajudando você a tomar decisões informadas e seguras.',
        },
        hearingInPerson: {
            id: 8,
            name: "Audiência Presencial",
            key: "hearingInPerson",
            icon: Description,
            grammaticalGender: 'feminine',
            description: 'Um advogado capacitado irá comparecer ao tribunal para representar e defender seus interesses. Você receberá um relatório detalhado sobre o andamento e os resultados da audiência, além de orientação sobre os próximos passos a serem tomados no processo judicial.',
        },
        issuePaymentFee: {
            id: 9,
            name: "Emissão de Guias",
            key: "issuePaymentFee",
            databaseKey: 'forwardToIssuePaymentFee',
            icon: Description,
            grammaticalGender: 'feminine',
            description: 'Emissão de documentos para o pagamento de custas processuais ou despesas relacionadas a um processo judicial.',
        }
    }
};

export const servicesByTypeKey = {
    legalDocument: {
        id: 1,
        services: [
            'analysis',
            'calculation',
            'petition'
        ]
    },
    calculation: {
        id: 2,
        services: [
            'calculation'
        ]
    },
    hearing: {
        id: 3,
        services: [
            'hearing',
            'hearingInPerson'
        ]
    }
};

export const onlyAdditionalServicesKey = ['hearingAnalysis']
