import { Button, Grid, IconButton as MuiIconButton, Typography } from "@material-ui/core";
import { Instagram, Language, LinkedIn, YouTube } from "@material-ui/icons";
import styled from "styled-components/macro";

const Wrapper = styled.div`
    padding: 0.2vh 25px;
    background: linear-gradient(135deg, #376fd0 0%, #2c5bb5 100%);
    color: white;
    box-shadow: 0px -2px 10px rgba(0,0,0,0.1);
`;

const IconButton = styled(MuiIconButton)`
    padding: 8px;
    margin: 0 4px;
    color: white;
    transition: all 0.2s ease-in-out;

    & svg {
        color: white;
    }

    &:hover {
        background-color: rgba(255,255,255,0.1);
        transform: translateY(-2px);
    }
`;

const StyledButton = styled(Button)`
    color: white;
    padding: 6px 20px;
    font-weight: 500;
    text-transform: none;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: rgba(255,255,255,0.4);
        border-color: rgba(255,255,255,0.8);
        transform: translateY(-2px);
        color: white;
    }
`;

const Copyright = styled(Typography)`
    font-size: 0.9rem;
    font-weight: 500;
    opacity: 0.9;
    color: white;
`;

const sendToLink = (url) => window.open(url, "_blank");

const SocialMediaButtons = () => (
    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
            <IconButton
                onClick={() => sendToLink('https://www.instagram.com/facilita.juridico/')}
                aria-label="Instagram"
            >
                <Instagram />
            </IconButton>
        </Grid>
        <Grid item>
            <IconButton
                onClick={() => sendToLink('https://www.youtube.com/c/FacilitaJur%C3%ADdico')}
                aria-label="YouTube"
            >
                <YouTube />
            </IconButton>
        </Grid>
        <Grid item>
            <IconButton
                onClick={() => sendToLink('https://www.linkedin.com/company/facilita-jur%C3%ADdico')}
                aria-label="LinkedIn"
            >
                <LinkedIn />
            </IconButton>
        </Grid>
        <Grid item>
            <IconButton
                onClick={() => sendToLink('https://facilitajuridico.com/')}
                aria-label="Website"
            >
                <Language />
            </IconButton>
        </Grid>
    </Grid>
);

const ExternalRegisterFooter = () => (
    <Wrapper>
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ margin: '0 auto' }}
            spacing={2}
        >
            <Grid item xs={4}>
                <Copyright>
                    © {new Date().getFullYear()} Facilita Jurídico - Todos os direitos reservados
                </Copyright>
            </Grid>

            <Grid item xs={4} container justifyContent="center">
                <StyledButton
                    onClick={() => sendToLink('https://bit.ly/3Qr8DBr')}
                    style={{
                        fontSize: '1rem',
                        color: '#fff'
                    }}
                >
                    Precisa de ajuda?
                </StyledButton>
            </Grid>

            <Grid item xs={4} container justifyContent="flex-end">
                <SocialMediaButtons />
            </Grid>
        </Grid>
    </Wrapper>
);

export default ExternalRegisterFooter;
