import { useState } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';

import RequestServiceDocumentList from './request.service.documents.list.component';
import RequestAnalysisCustomerView from '../analysis/request.analysis.customer.view';
import RequestHearingAnalysisCustomerView from '../hearing/request.hearing.analysis.customer.view';
import RequestHearingCustomerView from '../hearing/request.hearing.customer.view';
import DocumentPreview from '../../solicitations/documents/document.preview.component';
import modalities from '../../../domain/constant/modalities.constant';

const RequestServiceDocumentsContent = ({ documents, request }) => {

    const [preview, setPreview] = useState({ show: false });

    const handleOpenPreview = (file) => setPreview({ ...file, show: true });

    const specificContentByServiceKey = (item) => ({
        analysis: {
            name: item.name,
            icon: PlaylistAddCheck,
            action: () => (
                <RequestAnalysisCustomerView
                    requestId={item.id}
                    buttonVariant="contained"
                    buttonTitle="Acessar"
                    buttonWidth="100%"
                />
            )
        },
        hearingAnalysis: {
            name: item.name,
            icon: PlaylistAddCheck,
            action: () => (
                <RequestHearingAnalysisCustomerView
                    requestId={item.id}
                    buttonVariant="contained"
                    buttonTitle="Acessar"
                    buttonWidth="100%"
                />
            )
        },
        hearing: {
            name: item.name,
            icon: PlaylistAddCheck,
            action: () => (
                <RequestHearingCustomerView
                    requestId={item.id}
                    buttonVariant="contained"
                    buttonTitle="Acessar"
                    buttonWidth="100%"
                />
            )
        },
    })[item.key];

    const getServiceList = (item, index) => {

        const specificContent = specificContentByServiceKey(item);

        const documents = specificContent ? [specificContent, ...item.documents] : item.documents;

        return (
            <Grid container key={index} style={{ marginTop: 10 }}>

                <RequestServiceDocumentList
                    request={request}
                    documents={documents}
                    openPreview={handleOpenPreview}
                    serviceName={item.name}
                    isNotion={item.serviceKey == modalities.notion.key}
                />

            </Grid >
        )
    }

    const getAdditionalDocumentsList = () => {

        if (!documents.additionalDocuments?.length)
            return null;

        return (
            <Grid container style={{ marginTop: 20 }}>

                <Grid container alignItems='center' spacing={2}>

                    <Grid item>
                        <Typography variant='subtitle2' style={{
                            fontWeight: 600,
                            color: "#616161e0",
                            textTransform: 'uppercase',
                        }}>
                            Documentos complementares
                        </Typography>
                    </Grid>

                    <Grid item xs>
                        <Divider />
                    </Grid>

                </Grid>

                <RequestServiceDocumentList
                    request={request}
                    openPreview={handleOpenPreview}
                    documents={documents.additionalDocuments}
                />

            </Grid >
        );
    }

    return (
        <>

            {documents.documentsByService?.map(getServiceList)}

            {getAdditionalDocumentsList()}

            {preview.show && (
                <DocumentPreview file={preview}
                    onSelect={() => setPreview({ show: false })} />
            )}

        </>
    );
}

export default RequestServiceDocumentsContent;