import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Payment, CheckCircle, Schedule } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { getDocByCollection } from "../../../domain/services/api/firebase";


const WaitingPayment = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const transactionId = searchParams.get('transactionId');
    const productId = searchParams.get('productId');

    const redirectToPurchaseThank = (doc) => {
        const isFinished = doc.get('isFinished');
        const status = doc.get('status');

        if (isFinished && status === 'paid')
            window.location.href = `/customers/thanks-purchase${productId ? `?productId=${productId}` : ''}`;
    }

    useEffect(() => {
        if (transactionId) {
            getDocByCollection('transactions', transactionId, redirectToPurchaseThank);
        }
    }, [transactionId]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="80vh"
            textAlign="center"
            p={3}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={4}
            >
                <Payment style={{
                    fontSize: 80,
                    color: '#1976d2',
                    marginRight: '1rem'
                }} />
                <Schedule style={{
                    fontSize: 80,
                    color: '#1976d2',
                    marginRight: '1rem'
                }} />
                <CheckCircle style={{
                    fontSize: 80,
                    color: '#1976d2'
                }} />
            </Box>

            <Typography variant="h4" gutterBottom>
                Estamos processando seu pagamento
            </Typography>

            <Typography variant="body1" color="textSecondary" style={{ maxWidth: '600px' }}>
                Por favor, aguarde alguns instantes enquanto identificamos seu pagamento.
                Você será redirecionado automaticamente assim que a confirmação for concluída.
            </Typography>

            <Typography variant="body2" color="textSecondary" style={{ marginTop: '2rem' }}>
                Não feche ou atualize esta página
            </Typography>
        </Box>
    );
};

export default WaitingPayment;

