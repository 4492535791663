import { useEffect, useState } from "react";

import solicitationService from "../../../domain/services/solicitation/solicitation.service";
import memberService from "../../../domain/services/solicitation/member.service";
import SimpleModal from "../../shared/SimpleModal";
import { Alert } from "@material-ui/lab";
import LoadingSpin from "../../LoadingSpin";
import { Button } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function RequestFinancialEditionModal({
    isOpen = false,
    priceInfo,
    setAlert,
    handleClose = () => { },
    onSave = () => { },
}) {

    const isCustomerPrice = priceInfo.type === 'customer';

    const [newPrice, setNewPrice] = useState(0);
    const [creditAmount, setCreditAmount] = useState(1);
    const [error, setError] = useState(null);
    const [showInfo, setShowInfo] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const initialCredits = priceInfo?.billingIds?.length || 0;

    const creditHasChanged = initialCredits !== creditAmount;
    const newCreditAmount = creditAmount - initialCredits;

    const resetStates = () => {
        setNewPrice(null);
        setCreditAmount(1);
        setError(null);
        setShowInfo(false);
    }

    useEffect(() => {
        if (!isOpen) {
            resetStates();
            return;
        }

        setNewPrice(priceInfo.price || null);
        setCreditAmount(priceInfo?.billingIds?.length || 0);
    }, [isOpen]);

    const updateFunctionByType = {
        customer: ({ mainId, serviceId, billingIds }) => {
            const data = {
                creditAmount: newCreditAmount,
                billingIds: newCreditAmount < 0 ? billingIds.slice(0, newCreditAmount * -1) : undefined,
                billingId: newCreditAmount > 0 ? billingIds[0] : undefined,
            }

            return solicitationService.modifyRequestServices(data);
        },
        faciliter: ({ responsibleId, requestId }) => memberService.updateFaciliterPrice(requestId, responsibleId, newPrice),
        faciliterReviewer: ({ responsibleId, requestId }) => memberService.updateFaciliterPrice(requestId, responsibleId, newPrice),
    }

    const handleSavePrice = async () => {
        if (isSaving) return;

        setIsSaving(true);
        setError(null);

        const { type, serviceId, responsibleId, billingIds } = priceInfo;

        await updateFunctionByType[type](priceInfo)
            .then(() => {
                onSave(serviceId, type, newPrice, responsibleId, billingIds);
                setAlert('Novo preço salvo com sucesso!', 'success');
                handleClose();
            })
            .catch(err => setError(err?.response?.data?.message || 'Não foi possível salvar o valor.'))
            .finally(() => setIsSaving(false));
    }

    const handlePriceChange = (price) => {
        const isValidNumber = (price || '').toString().search(/^-?[\d,]+(\.\d*)?$/) >= 0;
        if (price && !isValidNumber) return;

        setNewPrice(price);
    }

    const renderDescription = (label, value) => (
        <div>
            <span className="text-[#0007] mr-2">{label}</span>{value}
        </div>
    );

    const buttonEnabled = creditHasChanged || (priceInfo.price != newPrice && !!(newPrice || '')?.toString().length);

    const renderAddingCreditAlert = () => (
        <Alert
            severity="info"
            variant="outlined"
            className="!bg-[#1890ff19]"
            style={{
                border: 'solid 1px #1890ff',
            }}
        >
            <div>
                {newCreditAmount > 1
                    ? <>Serão debitados cerca de mais <b>{newCreditAmount}</b> créditos no valor de <b>R$ {newPrice}</b> cada.</>
                    : <>Será debitado cerca de mais <b>1</b> crédito no valor de <b>R$ {newPrice}</b>.</>
                }
            </div>
            <button className="underline mt-1" onClick={() => setShowInfo(prev => !prev)}>
                Por que o valor não é exato?
            </button>
            {showInfo && (
                <div className="mt-1">
                    Pode ser que os créditos adicionais sejam debitados de pacotes diferentes, que possuem preços de serviço diferentes.
                </div>
            )}
        </Alert>
    );

    const renderRemovingCreditAlert = () => (
        <Alert
            severity="warning"
            variant="outlined"
            className="!bg-[#ff980011]"
        >
            <div>
                {newCreditAmount < -1
                    ? <>Serão estornados <b>{newCreditAmount * -1}</b> créditos no valor de <b>R$ {newPrice}</b> cada.</>
                    : <>Será estornado <b>1</b> crédito no valor de <b>R$ {newPrice}</b>.</>
                }
            </div>
        </Alert>
    );

    return (
        <SimpleModal
            open={isOpen}
            title={`Editar Preço - ${priceInfo.serviceName}`}
            maxWidth="sm"
            onClose={handleClose}
        >
            <div className="text-sm flex flex-col gap-4">

                <div className="flex mt-2">

                    <div className="w-[49%]">
                        {!isCustomerPrice && (
                            <div>
                                <div className="font-semibold mb-2">Preço</div>
                                <div
                                    className="relative flex w-fit pl-3 pr-6 py-1 gap-2 rounded-lg border border-[#dedede] hover:border-[#376fd0] focus-within:border-[#376fd0] transition-[border-color_.3s_ease] group"
                                >
                                    <div>R$</div>
                                    <input
                                        type="text"
                                        value={newPrice}
                                        className="outline-none"
                                        onChange={e => handlePriceChange(e.target.value)}
                                    >
                                    </input>
                                    <div className="absolute right-0 top-0 h-full opacity-0 group-hover:opacity-100 transition-[opacity_.6s_ease]">
                                        <button
                                            onClick={() => handlePriceChange(parseFloat(newPrice) + 1)}
                                            className="h-[calc(50%-1px)] flex items-center px-2 border-l border-[#ededed] hover:bg-[#00000004] hover:text-[#376fd0]"
                                        >
                                            <KeyboardArrowUp className="!text-sm" />
                                        </button>
                                        <div className="w-full h-[1px] bg-[#ededed]" />
                                        <button
                                            onClick={() => handlePriceChange(parseFloat(newPrice) - 1)}
                                            className="h-[calc(50%-1px)] flex items-center px-2 border-l border-[#ededed] hover:bg-[#00000004] hover:text-[#376fd0]"
                                        >
                                            <KeyboardArrowDown className="!text-sm" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isCustomerPrice && (
                            <div>
                                <div className="font-semibold mb-2">Quantidade de Créditos</div>
                                <div className="flex w-fit">
                                    <button
                                        onClick={() => setCreditAmount(prev => Math.max(prev - 1, 0))}
                                        disabled={creditAmount <= 1}
                                        className={`px-3 py-1 rounded-l-lg border border-[#dedede] font-semibold hover:text-[#376fd0] hover:border-[#376fd0] hover:bg-[#00000004] transition-[border-color_.3s_ease] disabled:opacity-30 disabled:hover:border-[#dedede] disabled:hover:text-inherit disabled:hover:bg-transparent disabled:cursor-not-allowed`}
                                    >
                                        -
                                    </button>
                                    <div className="px-3 py-1 border-t border-b border-[#dedede]">{creditAmount}</div>
                                    <button
                                        onClick={() => setCreditAmount(prev => prev + 1)}
                                        className="px-3 py-1 rounded-r-lg border border-[#dedede] font-semibold hover:text-[#376fd0] hover:border-[#376fd0] hover:bg-[#00000004] transition-[border-color_.3s_ease]"
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-[2%] flex justify-center">
                        <div className="w-[1px] h-full bg-[#dedede]" />
                    </div>
                    <div className="w-[49%] pl-6">
                        <div className="flex flex-col gap-4">
                            {renderDescription('Serviço:', priceInfo.label)}
                            {priceInfo.type != 'customer' && (
                                renderDescription('Faciliter:', priceInfo.responsibleName)
                            )}
                            {renderDescription('Total', `R$ ${priceInfo.type != 'customer' ? newPrice : newPrice * creditAmount}`)}
                        </div>
                    </div>
                </div>
                {creditHasChanged && (
                    <div className="mt-2">
                        {newCreditAmount > 0 ? renderAddingCreditAlert() : renderRemovingCreditAlert()}
                    </div>
                )}

                {!!error && (
                    <Alert
                        severity="error"
                        variant="outlined"
                        className="!bg-[#fff2f0]"
                    >
                        {error}
                    </Alert>
                )}
                <div className="w-full h-[1px] my-4 bg-[#dedede]" />
                <div className="flex justify-end items-center">
                    <button
                        className="px-5 py-2 rounded-lg border border-[#dedede] mr-4 hover:text-[#376fd0] hover:border-[#376fd0] hover:bg-[#00000004] transition-[border-color_.3s_ease]"
                        onClick={handleClose}
                    >
                        Cancelar
                    </button>
                    <Button
                        className="!px-5 !py-2 !rounded-lg flex !text-sm"
                        onClick={handleSavePrice}
                        disabled={!buttonEnabled}
                        variant="contained"
                        color="primary"
                    >
                        <div
                            className="overflow-hidden transition-[width_.3s_ease]"
                            style={{
                                width: isSaving ? 28 : 0,
                            }}
                        >
                            <LoadingSpin color="#fff" />
                        </div>
                        Salvar
                    </Button>
                </div>
            </div>
        </SimpleModal>
    )
}