import { useState } from "react";
import styled from "styled-components/macro";

import {
    TextField as MuiTextField, Select as MuiSelect, Grid, MenuItem, Typography,
    Tooltip, Checkbox, FormHelperText, FormControlLabel, RadioGroup, Radio,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import FormFieldTitle from "./FormFieldTitle";

import { Alert, AlertTitle } from '@material-ui/lab';
import DateTimeTooltip from "./DateTimeTooltip";

const MultilineTextField = styled(MuiTextField)`
    .MuiOutlinedInput-input { padding: 0 }
`;

const TextField = styled(MuiTextField)`
    .MuiOutlinedInput-input { padding: 6px 6px 7px }
`;

const Select = styled(MuiSelect)`
    .MuiOutlinedInput-input { padding: 6px 6px 7px }
`;

const Autocomplete = styled(MuiAutocomplete)`
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] { padding: 0 }
`;

const FormField = (
    {
        field = '', name = '', placeholder = '', currentValue = '', onChange,
        disabled, clean, onError, titleSize = 18, readOnly, mask, cleanTitle,
        options = [], titleColor, color, spacing = 0, required, customOptions,
        type = 'text', size, helperText, titleWeight, onInputChange, warningHearingAntecipation, ...props
    }
) => {

    const [isTooltipOpen, setIsTooltipoOpen] = useState(false);

    const [leftEmpty, setLeftEmpty] = useState(false);

    const isWithError = () => (onError || leftEmpty);

    const handleCheckRequirement = () => {

        if (!required || !!currentValue || currentValue === false)
            return;

        setLeftEmpty(true);
    }

    const getNoOptionsText = () => {

        if (props.isLoading)
            return "Aguarde carregando opções...";

        return (props.hasCustomFieldOptions) ? "Digite para adicionar uma nova opção" : "Nenhuma opção encontrada";
    }

    const getRadioButtonField = () => (
        <RadioGroup
            id={name}
            row name={name} value={currentValue}
            onChange={(e, value) => onChange(name, value)}
        >
            {options.map((item, index) => (
                <FormControlLabel
                    key={index} label={item.name} value={item.value}
                    control={<Radio disabled={item.disabled || disabled} />}
                />
            ))}
        </RadioGroup>
    )

    const getAutocompleteField = () => {

        const value = props.multiple ?
            currentValue || []
            : options.find(op => op.value == currentValue) || '';

        return (
            <Tooltip title={value?.name || ''}>
                <Autocomplete
                    id={name}
                    name={name}
                    error={isWithError().toString()}
                    value={value}
                    noOptionsText={getNoOptionsText()}
                    disabled={disabled}
                    options={options}
                    readOnly={disabled}
                    getOptionLabel={option => (option.name || "")}
                    onBlur={handleCheckRequirement}
                    onOpen={props.onOpen}
                    onChange={(e, selected) => {
                        leftEmpty && setLeftEmpty(false);
                        onChange(name, selected?.value, selected);
                    }}
                    renderOption={option => !props.multiple ? option.name : (
                        <div className="flex gap-2 items-center">
                            <Checkbox checked={value.some(val => val.id === option.id)} className="!p-0" />
                            <span>{option.name}</span>
                        </div>
                    )}
                    renderInput={props.customRenderInput || (
                        (params) => (
                            <TextField
                                {...params} placeholder={placeholder} error={isWithError()}
                                variant={clean ? "standard" : "outlined"} disabled={disabled}
                                {...(props.getCustomRenderProps && props.getCustomRenderProps(params))}
                            />)
                    )}
                    onInputChange={onInputChange}
                    {...props}
                >
                </Autocomplete>
            </Tooltip>
        )
    }

    const getDateField = () => (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <DatePicker
                id={name}
                name={name}
                value={currentValue}
                fullWidth
                required={required}
                error={isWithError()}
                okLabel="Confirmar"
                cancelLabel="Cancelar"
                format={props.format || "dd/MM/yyyy"}
                onChange={(value) => onChange(name, value)}
                disabled={disabled}
                placeholder={placeholder}
                {...props}
            />
        </MuiPickersUtilsProvider>
    )

    const getDateTimeField = () => (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <DateTimeTooltip>
                    <DateTimePicker
                        id={name}
                        name={name}
                        value={currentValue}
                        minDate={props.minDate}
                        minutesStep={props.minutesStep || 5}
                        okLabel="Confirmar"
                        cancelLabel="Cancelar"
                        format={props.format || "dd/MM/yyyy HH:mm"}
                        onChange={(value) => onChange(name, value)}
                        required={required}
                        fullWidth error={isWithError()}
                        disabled={disabled}
                        placeholder={placeholder}
                        {...props}
                />
            </DateTimeTooltip>
        </MuiPickersUtilsProvider>
    )

    const getSelectionField = () => {

        const selectPlaceHolder = !currentValue ?
            () => <div style={{ color: "#aaa" }}>{placeholder}</div>
            : null;

        return (
            <Select
                id={name}
                name={name}
                value={currentValue}
                error={isWithError()}
                fullWidth variant={clean ? "standard" : "outlined"}
                disabled={disabled}
                onBlur={handleCheckRequirement}
                renderValue={selectPlaceHolder} displayEmpty
                onChange={(e) => {
                    leftEmpty && setLeftEmpty(false);
                    onChange(e.target.name, e.target.value, e.target);
                }}
                {...props}
            >
                {options.map((item, index) =>
                    <MenuItem
                        id={item.value}
                        key={index}
                        value={item.value}
                        disabled={currentValue == item.value}
                    >
                        {!customOptions ? item.name : customOptions(item, index)}
                    </MenuItem>
                )}
            </Select>
        )
    }

    const getTextField = () => (
        <TextField
            id={name}
            variant={clean ? "standard" : "outlined"}
            placeholder={placeholder} value={currentValue}
            name={name} error={isWithError()} fullWidth
            onBlur={handleCheckRequirement} disabled={disabled}
            onChange={(e) => {
                leftEmpty && setLeftEmpty(false);
                const value = mask ? mask(e.target.value) : e.target.value;
                onChange(e.target.name, value);
            }}
            {...props}
        />
    )

    const getMultilineTextField = () => (
        <MultilineTextField
            id={name}
            variant={clean ? "standard" : "outlined"}
            placeholder={placeholder} value={currentValue}
            name={name} error={isWithError()} fullWidth
            onBlur={handleCheckRequirement} disabled={disabled}
            onChange={(e) => {
                leftEmpty && setLeftEmpty(false);
                const value = mask ? mask(e.target.value) : e.target.value;
                onChange(e.target.name, value);
            }}
            size={size} multiline
            InputProps={props.customInputProps}
        />
    )

    const getCheckbox = () => {

        if (type != 'checkbox')
            return null;

        return (
            <Checkbox
                id={name}
                checked={currentValue}
                onChange={(e, value) => onChange(name, value)}
                disabled={disabled}
            />
        )
    }

    const getHelperText = () => {

        const text = type == 'checkbox' ? placeholder : helperText;
        const style = name == 'hearingDateTime' ? {
            fontWeight: 600, fontSize: 14, color: '#FF0000'
        } : {}

        if (!text)
            return null;

        return (
            <FormHelperText style={ style }>
                {text}
            </FormHelperText>
        )
    }

    const fieldComponentByKey = {
        select: getSelectionField,
        text: getTextField,
        autocomplete: getAutocompleteField,
        multiline: getMultilineTextField,
        radioButton: getRadioButtonField,
        date: getDateField,
        datetime: getDateTimeField
    }

    const getContent = () => {

        if (readOnly)
            return (
                <Typography style={{ fontSize: props.fontSize || 18, color: color || 'black' }} >
                    {
                        options.length > 0 && !!options.find(item => item.value == currentValue) ? (
                            options.find(item => item.value == currentValue)?.name
                        ) : (
                            currentValue
                        )
                    }
                </Typography>
            )

        const fieldComponent = fieldComponentByKey[type];

        return (
            <Tooltip
                open={isWithError() && isTooltipOpen} arrow
                onClose={() => setIsTooltipoOpen(false)}
                onOpen={() => isWithError() && setIsTooltipoOpen(true)}
                title={`É necessário preencher ${!field ? 'este campo' : `o ${field}`}`}
            >
                {fieldComponent() || getTextField()}
            </Tooltip>
        )
    }

    return (
        <Grid item>

            <Grid container alignItems="center" style={{ flexWrap: 'nowrap' }}>

                {getCheckbox()}

                {!!field && (
                    <FormFieldTitle
                        {...{ titleColor, color, spacing, titleSize, titleWeight, clean, cleanTitle, field, type }}
                        onError={isWithError()}
                    />
                )}

            </Grid>

            {type != 'checkbox' && getContent()}

            {getHelperText()}

        </Grid >
    )
}

export default FormField;