export const getAutomaticReviewStepProps = (request) => {
    if(request.isInCorrection) return {
        label: "Em correção: Revisão com IA indisponível",
        description: 'A solicitação está em correção. A revisão com IA não poderá ser feita',
        color: '#c73838'
    }

    const stepStrategy = {
        "messageToDevelopers": {
            label: "Inadequação na Revisão com IA",
            description: `Mensagem da IA revisora: ${request.automaticProductionInfo.messageToDevelopers}`,
            color: 'orange'
        },
        "failedAt": {
            label: "Falha na Revisão com IA",
            description: 'A revisão com IA falhou',
            color: '#c73838'
        },
        "finishedAt": {
            label: "Revisão feita com IA",
            description: 'A revisão do serviço foi feita com IA',
            color: 'green'
        },
        "enterLoadDocsQueueAt": {
            label: "A Revisão está sendo feita com IA",
            description: 'A revisão do serviço está sendo feita com IA',
            color: '#498c8b'
        },
        "enterGenerateQueueAt": {
            label: "A Revisão está sendo feita com IA",
            description: 'A revisão do serviço está sendo feita com IA',
            color: '#498c8b'
        },
        "default": {
            label: "Revisão será feita com IA",
            description: 'Revisão do serviço será feito com IA',
            color: 'gray'
        }
    };

    const step = Object.keys(stepStrategy)
    .find(key => request.automaticProductionInfo[key]) ? stepStrategy[Object.keys(stepStrategy).find(key => request.automaticProductionInfo[key])] : stepStrategy["default"];

    return step
}