import React, { useState, useEffect } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import {
    List, ListItem, ListItemText, ListItemIcon, TextField, Button, Avatar,
    Tooltip, Typography, Grid, Dialog, DialogTitle, DialogContent,
    DialogActions, Chip, CircularProgress, CardContent, Card, Snackbar
} from "@material-ui/core";

import { CheckBox, PersonAdd } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import EmptyList from "../EmptyList";

import loginService from "../../domain/services/login.service";
import memberService from "../../domain/services/solicitation/member.service";
import CorrespondentService from "../../domain/services/correspondent.service";
import api from "../../domain/services/api";
import documentsService from "../../domain/services/solicitation/documents.service";

import swal from 'sweetalert2';
import stepsSolicitation from "../../domain/constant/steps.solicitation.constant";
import { requestServices } from "../../domain/constant/request.services.constant";


function SelectMembers(props) {

    const [correspondents, setCorrespondents] = useState([]);
    const [members, setMembers] = useState([]);
    const [member, setMember] = useState({});

    const [open, setOpen] = useState(false);
    const [filterMembers, setFilterMembers] = useState("");

    const [statusMsg, setStatusMsg] = useState({});

    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => getMembers(), []);

    useEffect(() => {
        let isSubscribed = true;

        if (isSubscribed && !props.isTableView)
            recoveryMembersRequest(props.members);

        return () => isSubscribed = false;
    }, [props.members]);

    const getMembers = () => {
        if (!props.isTableView)
            findCorrespondents();

        recoveryMembersRequest(props.members);
    }

    const hearingInPerson = (props.requestInfo?.serviceId === requestServices.structure.hearingInPerson.id);

    const findCorrespondents = async (filters = []) => {
        const params = {
            filter: [{ name: 'isInactive', value: [false] }, { name: 'isBlocked', value: [false] }, ...filters],
            orderBy: [{ name: 'name', value: 'asc' }],
            pagination: { pageIndex: 0, pageSize: !hearingInPerson ? 50 : null },
            request: { city: props.requestInfo?.city, isHearingInPerson: hearingInPerson },
        }
        const results = await CorrespondentService.filter(params).then(response => {
            const results = api.extractValueByKey('results', response)
            return results
        })
        setCorrespondents(results);
        refreshList(results);
    }

    const onChange = (field, value) => {
        member[field] = value;
        setMember({ ...member });
    }

    const findMembers = async () => {

        const { data } = await memberService.find(props.requestId).catch(err => ({ data: [] }));

        setMembers(data);
        return data;
    }

    const recoveryMembersRequest = (members) => {
        let item = (members || []).map(item => {
            item.id = item.responsibleId;
            return item;
        });
        setMembers(item);
        return item;
    }

    const checkMember = async (member) => {
        if (!!member.checked) {
            setMember({ ...member, isConfirmRemove: true });
            return;
        }
        await save(member);
    }

    const handleDelete = (member) =>
        setMember({ ...member, memberId: member.id, isConfirmRemove: true });

    const save = async (member) => {

        await trackPromise(
            memberService.save(props.requestId, member)
                .then(() => setAlertStatus("Membro adicionado com sucesso", "success"))
                .then(() => refreshList(correspondents))
                .then(() => props.onRefreshRequest(props.requestId, props.step))
                .catch((error) => setAlertStatus(error.response.data.message, "error"))
        );
    }

    const checkToRemove = async (member, requestId, serviceId) => {

        const { data } = await documentsService
            .findServiceDocumentBySenderUserId(requestId, serviceId, member.userId)
            .catch(() => ([]));

        if (!data.length)
            return await checkToPenalizeFaciliterDropout(member, requestId);

        swal.fire({
            title: `Realmente deseja remover o faciliter do serviço?`,
            text: `O Faciliter já anexou documentos de serviço.`,
            icon: "warning",
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            customClass: { container: 'swal-container' }
        }).then(result => result.isConfirmed && checkToPenalizeFaciliterDropout(member, requestId));
    }

    const checkToPenalizeFaciliterDropout = async (member, requestId) => {

        const isInCorrection = props.requestInfo?.isInCorrection;
        if (!isInCorrection)
            return await remove(member, requestId);

        swal.fire({
            title: `Deseja penalizar o faciliter pela desistência do serviço?`,
            text: `O faciliter receberá um nota baixa por esse serviço e terá seu score reduzido. Além disso, a remoção do faciliter será considerada pelo sistema como desistência na validação de permanência no programa Renda Garantida.`,
            icon: "warning",
            cancelButtonText: 'Continuar sem penalizar',
            confirmButtonText: 'Penalizar Faciliter',
            showCancelButton: true,
            customClass: { container: 'swal-container' }
        }).then(result => {
            if (result.dismiss === 'backdrop') return;
            result.isConfirmed ? remove({ ...member, penalizeDropout: true }, requestId) : remove(member, requestId);
        });
    }

    const remove = async (member, requestId) => {

        if (!member.reason) {
            setAlertStatus('Informe o motivo da exclusão', "warning")
            return;
        }

        const price = (parseFloat(member.price) || 0);
        const addtionalInformation = { reason: member.reason };
        const penalizeDropout = member.penalizeDropout;

        await trackPromise(
            memberService
                .remove({ id: member.memberId, requestId, price, addtionalInformation, penalizeDropout })
                .then(() => setAlertStatus("Membro excluído com sucesso", "success"))
                .catch((error) => setAlertStatus(error.response.data.message, "error"))
                .finally(() => onFinishRemove())
        );
    }

    const onFinishRemove = async () => {

        refreshList(correspondents);
        setMember({ isConfirmRemove: false });

        const requestValues = await props.onRefreshRequest();

        const availableStepToResendLink = [
            stepsSolicitation.NOT_STARTED, stepsSolicitation.IN_PROGRESS
        ].includes(requestValues.status);

        if (!availableStepToResendLink || (requestValues.members || []).length)
            return;

        swal.fire({
            text: `Não foi possível encaminhar para vinculação, para conseguir será necessário ajustar o prazo do cliente`,
            confirmButtonText: 'Ajustar prazo',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            width: 450,
            padding: '1em',
            customClass: { container: 'swal-container' }
        }).then(result =>
            result.isConfirmed && document.getElementsByName("allServicesDueDate")[0].click()
        );
    }

    const refreshList = async (correspondents) =>
        Promise.resolve(findMembers())
            .then(members => mapWithCorrespondent(members, correspondents));

    const mapWithCorrespondent = (members, correspondents) => {
        const result = memberService.map(props.requestId, correspondents, members);
        setCorrespondents(result);
        return result;
    }

    const searchMembers = (search) => {
        let filterList = correspondents;

        if (search)
            filterList = filterList.filter((item) => item.name.toLowerCase().indexOf(search) != -1);

        return filterList;
    }

    const changeFilterMembers = (value) => {
        setFilterMembers(value);

        if (value.length < 3)
            return

        findCorrespondents([{ name: 'name', value }]);
    }

    const openMembers = () => {
        setOpen(true);

        if (!correspondents.length)
            trackPromise(findCorrespondents());
    }

    const closeModel = () => {
        setOpen(false);
    }

    const membersForm = () => {
        return (
            (searchMembers(filterMembers) || []).map((member, i) => (
                <ListItem button style={{ paddingLeft: "0" }} onClick={() => { checkMember(member) }} key={i}>
                    <ListItemIcon>
                        <Avatar alt={member.name} src="/static/img/avatars/avatar-2.jpg"
                            style={{
                                width: "28px", height: "28px", fontSize: "11px", fontWeight: 600,
                                background: "#376fd0", color: "#fff"
                            }}>{loginService.getNameInitials(member.name)}</Avatar>
                    </ListItemIcon>
                    <ListItemText primary={member.name} style={{ whiteSpace: "nowrap", width: "18em", overflow: "hidden", textOverflow: "ellipsis" }} />
                    {member.checked ? <CheckBox style={{ fontSize: "20px", color: "#a4dd00" }} /> : null}
                </ListItem>
            ))
        );
    }

    const addMembers = (members) => {
        if (!members.length)
            return (
                <Tooltip title="Adicionar Faciliter">
                    <Chip size="small" label="Faciliter" variant="outlined"
                        avatar={<PersonAdd style={{ width: "16px", height: "16px", color: '#5584AC' }} />}
                        style={{ fontWeight: "500", marginRight: "5px", marginBottom: "8px", color: '#5584AC', border: '1px solid #5584AC' }}
                        onClick={openMembers}
                    />
                </Tooltip>
            );
    }

    const getListMembers = (members) => {
        return (
            <div style={{ marginRight: "5px", width: "92px", display: "inline" }}>
                {members.map((item, index) => (
                    <Tooltip title={item.name} key={index} style={{ zIndex: "999999", cursor: "pointer" }}>
                        <Chip size="small" label="Faciliter" variant="outlined"
                            avatar={
                                <div style={{ padding: '5px', borderRadius: '100%', color: '#fff', background: '#5584AC', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {loginService.getNameInitials(item.name)}
                                </div>
                            }
                            style={{ fontWeight: "500", background: "#fff", color: '#5584AC', border: '1px solid #5584AC', marginBottom: "8px" }}
                            onDelete={() => { handleDelete(item); }}
                        />
                    </Tooltip>
                ))}
            </div>
        );
    }

    const confirmRemoveDialog = () => {

        const close = () =>
            setMember({ ...member, isConfirmRemove: false, checked: true });

        return <Dialog open={(member.isConfirmRemove || false)} onClose={close}>
            <DialogTitle>Remover membro</DialogTitle>
            <DialogContent>

                <TextField id="reason" autoFocus margin="dense" label="Motivo da inativação" fullWidth
                    onChange={(e) => { onChange('reason', e.target.value); }} />

            </DialogContent>
            <DialogActions>

                <Button onClick={close} color="primary">Cancelar</Button>

                <Button
                    color="primary"
                    onClick={() => {
                        checkToRemove(member, props.requestId, props.serviceId)
                    }}
                >
                    Excluir
                </Button>

            </DialogActions>
        </Dialog>

    }

    const loading = () => {
        return (
            promiseInProgress &&
            <Card mb={12} xs={12} style={{ marginTop: '10px', width: '100%', boxShadow: "unset" }}>
                <CardContent style={{ display: "-webkit-inline-box", paddingTop: "20px", paddingBottom: "20px" }}>
                    <CircularProgress m={2} color="secondary" style={{ marginTop: "5px", width: "34px", height: "34px" }} />
                    <Grid item style={{ marginLeft: "20px", width: '100%' }}>
                        <Typography variant="h6" gutterBottom> Aguarde </Typography>
                        <Typography variant="body2" gutterBottom> carregando lista... </Typography>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    const setAlertStatus = (msg, severity) => setStatusMsg({ text: msg, date: new Date(), open: true, severity });

    const handleCloseAlert = () => setStatusMsg({ text: "", date: new Date(), open: false });

    return (
        <React.Fragment>

            {(members || []).length ? getListMembers(members) : null}
            {addMembers(members || [])}

            <Dialog name="modalMember" open={open} disableEscapeKeyDown>
                <DialogTitle>Responsáveis</DialogTitle>

                <TextField name="search" label="Buscar membros" value={filterMembers}
                    onChange={(e) => { changeFilterMembers(e.target.value.toLowerCase()) }}
                    style={{ margin: "0 20px 10px" }} my={2} />

                <DialogContent>
                    <List component="nav" dense={true} style={{ padding: "0", height: "120px", overflow: "auto" }}>
                        {loading()}
                        {(!promiseInProgress && correspondents.length) ? membersForm() : <EmptyList />}
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeModel} color="primary"> Fechar </Button>
                </DialogActions>
            </Dialog>

            {confirmRemoveDialog()}

            <Snackbar onClose={handleCloseAlert} open={statusMsg.open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000} key={statusMsg.date}>
                <Alert severity={statusMsg.severity}>
                    {statusMsg.text}
                </Alert>
            </Snackbar>

        </React.Fragment>
    );
}

export default SelectMembers;


