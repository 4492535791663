
import React from "react";

import Async from "../../components/Async";

import { ShoppingCartOutlined } from "@material-ui/icons";

const AuthGuard = Async(() => import("../auth.guard"));

const MarketPlaceView = Async(() => import("../../pages/marketplace/marketplace"));
const CreditPurchaseView = Async(() => import("../../pages/marketplace/credit.purchase"));
const Checkout = Async(() => import('../../pages/marketplace/checkout'));

const routes = {
    marketPlace: {
        id: 'marketPlace',
        name: 'Júris Store',
        path: '/marketplace',
        component: MarketPlaceView,
        guard: AuthGuard,
        icon: <ShoppingCartOutlined />,
        roles: ['customer', 'customer-user-admin'],
        layoutProps: {
            hideSideBar: true,
        }
    },
    creditPurchase: {
        id: 'creditPurchase',
        name: 'Comprar Créditos',
        path: '/marketplace/creditPackage',
        component: CreditPurchaseView,
        disabledMenu: true,
        guard: AuthGuard,
        roles: ['customer', 'customer-user-admin'],
        layoutProps: {
            hideSideBar: true,
        }
    },
    checkout: {
        id: 'checkout',
        path: '/checkout/:productType/:productId',
        component: Checkout,
        guard: AuthGuard,
        roles: ['customer', 'customer-user-admin'],
        layoutProps: {
            hideSideBar: true,
        }
    },
}

const externalRoutes = {
    externalCutomersCheckout: {
        id: 'registerCheckout',
        path: '/customers/external-new/checkout/:productType/:productId',
        component: Checkout,
        guard: AuthGuard,
        roles: ['customer', 'customer-user-admin'],
    },
    externalCheckoutWithVoucher: {
        id: 'externalCheckoutWithVoucher',
        path: '/external-checkout/:productType/:productId/voucher/:voucherCode',
        props: { withRegister: true },
        component: Checkout,
    },
    externalCheckout: {
        id: 'externalCheckout',
        path: '/external-checkout/:productType/:productId/:sellerUserId?',
        props: { withRegister: true },
        component: Checkout,
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}