import { Typography, Tooltip } from "@material-ui/core";
import { AccessTimeOutlined, Check } from "@material-ui/icons";

import datetimeProvider from "../../providers/datetime.provider";

import loginService from "../../domain/services/login.service";

export default function RequestServiceInformationHearingDateInfo({
    dateTimeInfo,
    isCheckin
}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            <Tooltip title={isCheckin ? 'O faciliter confirmou que estava disponível para realizar a audiência nesse horário' : 'Data e Hora da Audiência'}>
                <Typography style={{ color: '#000000DE' }}>
                    {isCheckin ? 'O faciliter realizou o check-in em' : 'Data e Hora da Audiência'}
                </Typography>
            </Tooltip>

            <Typography color="secondary" style={{
                fontWeight: 500, fontSize: 20,
                display: 'flex', alignItems: 'end', lineHeight: 1.22, paddingTop: 4
            }}>

                {isCheckin ?
                    <Check style={{ marginTop: 2, marginRight: 4 }} />
                    : <AccessTimeOutlined style={{ marginTop: 2, marginRight: 4 }} />
                }

                <span style={{ marginRight: 8 }}>
                    {datetimeProvider.formatDateTime(dateTimeInfo, 'DD/MM [às] HH:mm', true)}
                </span>

                {isCheckin ? null : (
                    <Typography variant='body2' style={{ color: '#0006' }}>
                        {datetimeProvider.getTimeDifferenceUntilDate(datetimeProvider.getDate(), dateTimeInfo, 'Audiência já realizada.')}
                    </Typography>
                )}

            </Typography>
            
            <Typography style={{
                fontWeight: 600, fontSize: 14, color: '#FF0000'
            }}>
                Horário no fuso horário de Brasília
            </Typography>

        </div>
    )
}