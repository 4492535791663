import React from 'react';

import styled, { withTheme } from 'styled-components/macro';

import { AppBar as MuiAppBar, Box } from '@material-ui/core';

import LogoFacilita from '../facilita-juridico-logo.png'

const AppBar = styled(MuiAppBar)`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    height: 50px;
`;

const Container = styled.div`
    max-width: 84%;
    display: flex;
    justify-content: end;
`;

const LogoTag = styled.div`
    background: white;
    height: 60px;
    width: 45px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.40);

    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExternalRegisterHeader = () => (
    <React.Fragment>
        <AppBar position='fixed' elevation={0}>
            <Container>
                <LogoTag>
                    <Box
                        component='img'
                        alt='Logo Facilita Jurídico'
                        src={LogoFacilita}
                        sx={{ width: 32, marginBottom: -4 }}
                    />
                </LogoTag>
            </Container>
        </AppBar>
    </React.Fragment>
);

export default withTheme(ExternalRegisterHeader);
