import { get, post, put, events as event } from '../api';

import EventEmmiter from '../../../providers/event.emmiter';
import { stepsTransactions } from '../../constant/status.transactions.constant';
import { paymentMethods, paymentMethodsByProduct } from '../../constant/payment.methods.constant';
import moment from "moment";
import { formatNumberCurrencyWithoutSymbol } from '../../../components/providers/utils.provider';

import customerPurchaseService from './customer.purchase.service';
import transactionInterface from '../../interfaces/transaction.interface';

export const events = new EventEmmiter();

const findAll = async () => {

    event.emmit('onLoadingList', true);
    const res = await get({ type: 'user', service: 'financial/transactions' });
    event.emmit('onLoadingList', false);

    return res
}

const findByCustomerId = async (customerId) => {
    const res = await get({ type: 'user', service: `financial/transactions/customer/${customerId}` });
    return res.data;
}

const update = async (data) => {

    const updateData = await transactionInterface.update(data);

    if (!updateData)
        return {}

    const netPriceObj = (!data.price || !data.tax)
        ? {}
        : { netPrice: customerPurchaseService.getNetPrice(data.price, (data.tax || 0)) };

    return await put({
        type: 'user',
        service: `financial/transactions`,
        data: { ...updateData, ...netPriceObj }
    });
}

const getInstallments = async (data) =>
    await post({ type: 'user', service: `marketing/packages/installments`, data });

const saveExternalAction = async (data) =>
    (data.id) ? update(data) : customerPurchaseService.purchaseExternal(data);

const mapDiscountValues = (voucher, data) => ({
    ...voucher,
    ...data[0],
    discountValues: {
        amount: data[0].price,
        installments: data,
        netPrice: data[0].netPrice,
        requestPrice: data[0].requestPrice,
        tax: data[0].tax
    }
})

const isProgress = (status) => (
    status == "processing" ||
    status == "waiting_payment" ||
    status == "pending_refund" ||
    status == "analyzing" ||
    status == "pending_review"
)

const getStepByKey = (statusKey) =>
    (stepsTransactions.stepTypes[statusKey] || {});

const getPaymentMethodByKey = (statusKey) =>
    (paymentMethods.types[statusKey] || {});

const findAvailablePaymentsTypes = (productType) =>
    productType ? paymentMethodsByProduct[productType] : paymentMethods.types;

const findAvailableStepsTransactions = () => stepsTransactions.stepTypes

const getFeedbackType = (key) => ({
    paid: "success",
    refused: "error",
    expired: "error",
    authorized: "info",
    refunded: "info",
    chargedback: "info"
})[key];

const mapToExcel = (transactions) => {
    return transactions.map((item) => ({
        "ID da Transação": item.id,
        "ID Pagarme": item.transactionProviderId,
        "ID do Cliente": item.customerId,
        "Cliente": item.name,
        "Status": getStepByKey(item.status).value,
        "Preço": formatNumberCurrencyWithoutSymbol(item.price),
        "Nº de Parcelas": item.installments,
        "Taxa": formatNumberCurrencyWithoutSymbol(item.tax),
        "Valor líquido": formatNumberCurrencyWithoutSymbol(item.netPrice),
        "Método de Pagamento": paymentMethods?.types[item.paymentMethod]?.value,
        "Nº de Parcelas": item.installments,
        "Descrição do Produto": item.productId ? `${item.packageDescription || '-'} (ID do Pacote: ${item.productId})` : item.requestId ? `Solicitação - Avulsa (ID da Solicitação: ${item.requestId})` : ``,
        "Criada em": moment(item.created).format('DD/MM/YYYY HH:mm'),
        "Última modificação em": moment(item.updated).format('DD/MM/YYYY HH:mm'),
        "Tipo de Produto": item.productTypeName,
        "Produto": item.productName,
        "Vendedor": item.sellerName,
        "Pago em": item.paid ? moment(item.paid).format('DD/MM/YYYY HH:mm') : "-",
        "É cliente novo?": item.isNewCustomer ? "Sim" : "Não",
    }))
}

export default {
    findAll,
    saveExternalAction,
    getInstallments,
    mapDiscountValues,
    isProgress,
    getFeedbackType,
    getStepByKey,
    getPaymentMethodByKey,
    findAvailablePaymentsTypes,
    findAvailableStepsTransactions,
    mapToExcel,
    findByCustomerId,
}