import moment from "moment";

import masks from "../../../providers/masks";
import exportationProvider from "../../../providers/exportation.provider";
import { flattenObject, getMissingFields } from "../../../components/providers/utils.provider";
import { events } from "../api";

import customerInterface from "../../interfaces/customer.interface";

import { filter } from "../client/filter.service";
import repository from '../../repositories/customer/customer.repository';
import datetimeProvider from "../../../providers/datetime.provider";
import loginService from "../login.service";

const findByFilter = async ({ filters = [], pageIndex, pageSize, sortBy = [] }, activePeriodFilters = []) =>
    filter(
        {
            filters: [
                ...filters.map(({ id, value }) => ({ name: id, key: id, value })),
                ...activePeriodFilters.map(({ name, value }) => ({
                    name,
                    value,
                })),
            ],
            sortBy: sortBy.map(({ id, desc }) => ({
                id: masks.camelToSnakeCase(id),
                desc,
            })),
        },
        { pageIndex, pageSize }
    ).then(res => res.data);

const mapToExcel = (clients) =>
    clients.map((item) => ({
        ID: item.id,
        Nome: item.name,
        Email: item.email,
        Celular: masks.cellPhone(item.telephone),
        "Criado em": item.created && moment.utc(item.created).format("DD/MM/YYYY"),
        "Data da Última Compra":
            item.lastPurchaseDate &&
            moment.utc(item.lastPurchaseDate).local().format("DD/MM/YYYY"),
        "Valor Total Investido": item.totalAmountInvested,
        "Pendência  Financeira": item.isFinancialPending ? "SIM" : "NÃO",
        CPF: item.personType == 'individual' && item.identity ? masks.cpf(item.identity) : '',
        CNPJ: item.personType == 'corporation' && item.identity ? masks.cnpj(item.identity) : '',
        "Nome Fantasia": item.company && item.company.fantasyName,
        "Razão Social": item.company && item.company.companyName,
        "Observações interna":
            item.additionInformation && item.additionInformation.observation,
        Endereço:
            item.address &&
            `${item.address.street}, ${item.address.district}, ${item.address.number}`,
        UF: item.address && `${item.address.state}`,
        Cidade: item.address && `${item.address.city}`,
        Rua: item.address && `${item.address.street}, ${item.address.number}`,
        Bairro: item.address && `${item.address.district}`,
        Complemento: item.address && item.address.complement,
        CEP: item.address && item.address.zipCode,
        "Canal de Aquisição": item.acquisition?.channel,
        "Outro Canal de Aquisição": item.acquisition?.description || ''
    }));

const exportByFilter = async ({ filters, sortBy }) => {

    const exportationParams = { filters, sortBy, pageIndex: 0, pageSize: 99999 }

    return findByFilter(exportationParams)
        .then(data => exportationProvider.exportData(
            mapToExcel(data.results), "clientes",
            [
                { wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 35 }, { wch: 35 },
                { wch: 50 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 },
                { wch: 30 }, { wch: 80 }, { wch: 35 }, { wch: 35 }, { wch: 35 },
                { wch: 35 }, { wch: 35 }, { wch: 20 }, { wch: 20 }, { wch: 35 },
                { wch: 35 }, { wch: 50 }, { wch: 50 }, { wch: 50 }, { wch: 50 },
            ]
        ));
}

const saveAction = async (client) =>
    client.id ? edit(client.id, { ...client }) : repository.saveInternal({ ...client });

const save = async (data) =>
    repository.save(data);

const saveLead = async (data) =>
    repository.saveLead(data);

const removeClient = async (id, data) =>
    repository.removeClient(id, data)

const externalSave = async (data) =>
    repository.externalSave(data);

const edit = async (id, data) => {

    const updateData = await customerInterface.update({ ...data, id });

    if (!updateData)
        return {};

    return repository.edit(id, data);
};

const registerEdition = async (id, data) =>
    repository.registerEdition(id, data);

const findAll = async () => {
    events.emmit("onLoadingList", true);
    const res = await repository.findAll();
    events.emmit("onLoadingList", false);

    return res;
};

const findById = async (id) =>
    repository.findById(id);

const findByEmail = async (id) =>
    repository.findByEmail(id);

const findByUUID = async (uuid) =>
    repository.findByUUID(uuid);

const getUUID = async () =>
    repository.getUUID();

const newClient = () => ({ name: "", email: "", telephone: "" });

const getTextFormatRequirement = (id) =>
    repository.getTextFormatRequirement(id);

const getRepurchaseRate = (period, yearMonth) =>
    repository.getRepurchaseRate(period, yearMonth);

const getUsageRate = (data) =>
    repository.getUsageRate(data);

const baseRequiredFieldsToUpdate = [
    {
        key: 'name',
        label: 'Nome'
    },
    {
        key: 'email',
        label: 'E-mail'
    },
    {
        key: 'telephone',
        label: 'Telefone'
    },
    {
        key: 'personType',
        label: 'Tipo de Pessoa'
    },
    {
        key: 'identity',
        label: 'CPF/CNPJ'
    },
    {
        key: "oab",
        label: "OAB"
    },
    {
        key: 'zipCode',
        label: 'CEP'
    },
    {
        key: 'street',
        label: 'Rua'
    },
    {
        key: 'district',
        label: 'Bairro'
    },
    {
        key: 'state',
        label: 'UF'
    },
    {
        key: 'city',
        label: 'Cidade'
    },
    {
        key: 'number',
        label: 'Número'
    }
];

const getRequiredFieldsToUpdate = (customer) => {

    if (!customer.id)
        return [
            {
                key: 'name',
                label: 'Nome'
            }, {
                key: 'email',
                label: 'E-mail'
            },
            {
                key: 'telephone',
                label: 'Telefone'
            }
        ];

    const requiredFields = [...baseRequiredFieldsToUpdate];

    if (customer.personType == 'corporation')
        requiredFields.push({ key: 'companyName', label: 'Razão Social' });

    return requiredFields;
}

const checkFieldsToSave = (customer) => {

    const requiredFields = getRequiredFieldsToUpdate(customer);
    const allFields = flattenObject(customer);

    const missingFields = getMissingFields(allFields, requiredFields.map(f => f.key));

    return requiredFields.filter(rf => missingFields.some(mf => mf == rf.key));
}

const updateAbandonedCheckout = (checkoutInfo) => {

    const body = {
        abandonedCheckoutInformation: {
            productId: checkoutInfo.product.id,
            productTypeId: checkoutInfo.product.productTypeId,
            productType: checkoutInfo.product.type,
            quantity: checkoutInfo.product.packageAmount,
            paymentMethod: checkoutInfo.paymentMethod,
            voucherCode: checkoutInfo.voucher?.code,
            date: datetimeProvider.getDate().format('YYYY-MM-DD HH:mm')
        }
    };

    const user = loginService.getUserAuthentication();

    if (!user?.customer?.id)
        return;

    edit(user.customer.id, body).catch(err => null);
}

export default {
    saveAction,
    saveLead,
    save,
    externalSave,
    edit,
    registerEdition,
    removeClient,
    findAll,
    findById,
    findByUUID,
    getUUID,
    newClient,
    getTextFormatRequirement,
    getRepurchaseRate,
    getUsageRate,
    findByFilter,
    exportByFilter,
    checkFieldsToSave,
    findByEmail,
    updateAbandonedCheckout,
};